import React, { useEffect, useState, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  UPDATE_OVERLAP_PRESENT,
  UPDATE_PRED_GOOD,
  UPDATE_ARM_POSITION,
} from "./api";
import { Radio, RadioGroup, Button, Select } from "@chakra-ui/core";
import mergeImages from "merge-images";
import moment from "moment";
import useKeyPress, { make_assign_variable, resize, show_single_part, make_rest_transparent, makePredictionData, createAllSegments, createImage, getMousePos, getPersonFromImage } from '../utility/helper-functions'
import {checkConnectionAndFillWithSome, checkConnectionAndFillWithRest, checkConnection} from "../utility/action-buttons"

import {
  allParts,
  allPartsForAssign,
  UPDATE_PERSON,
  TABLE,
  PRED_GOOD_AVAILABLE,
  GET_IMAGE_URL,
  UPDATE_PERSON_NA,
} from "../main";
import "./index.css";
import { parse } from "graphql";
import WorkingCanvas from "./WorkingCanvas";
import Review from "./Review";
const OFFSET_X = 0;
const OFFSET_Y = 0;
let SIZEX = 600;
let SIZEY = 800;
let imageObj;
let LEFT_POSITION = "1450px";


const MapPoints = (props) => {
  const {
    image,
    canvas_name,
    nextImage,
    id,
  } = props;
  let canvas = document.getElementById(canvas_name);
  imageObj = document.getElementById("image");
  const [dataUrl, setDataUrl] = useState("");
  const [preview, setPreview] = useState(false);
  const [opacity, setOpacity] = useState(5);
  const [saveAppropriate] = useMutation(UPDATE_PERSON_NA, {
    onCompleted(data) {
      if (data?.[`update_${TABLE}`].returning[0].is_na == "Yes") {
        nextImage();
      } else {
        alert("Saved !")
      }
    },
  });
  const [initTime, setInitTime] = useState(moment());
  const [predictionData, setPredictionData] = useState();
  const [showSave, setShowSave] = useState(false);
  const [update, setUpdate] = useState(false);
  const [liveReview, setLiveReview] = useState('');
  const connectToFIrstPointKeyDown = useKeyPress("c");
  const deleteLastPointKeyDown = useKeyPress("d");
  const opacityIncreaseKeyDown = useKeyPress("+");
  const opacityDecreaseKeyDown = useKeyPress("-");
  const saveKeyDown = useKeyPress("s")
  const personView = useKeyPress("p")
  const [showPart, setShowPart] = useState("");
  const [showPerson, setShowPerson] = useState(false);
  const [wasPointClickOutside, setWasPointClickOutside] = useState(false);

  let DEFAULT_VALUES = {};
  allPartsForAssign.map((one) => {
    DEFAULT_VALUES[make_assign_variable(one["variable"])] = "";
  });
  let BINARY_DEFAULT_VALUES = {};
  allParts.map((one) => {
    BINARY_DEFAULT_VALUES[one["variable"]] = "scratch";
  });
  const [formFields, setFormFields] = useState(DEFAULT_VALUES);
  const [startFromScratchOrUseSaved, setStartFromScratchOrUseSaved] = useState({});
  const [segmentationsPerClass, setSegmentationsPerClass] = useState({});
  const [predType, setPredType] = useState("");

  const onInputChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };
  let showTemp = {};
  for (let i = 0; i < allParts.length; i += 1) {
    showTemp[allParts[i]["variable"]] = true;
  }


  useEffect(() => {
    if (props.loadImage == "segment") {
      setOpacity(10);
    } else {
      setOpacity(5);
    }
  }, [props.loadImage]);

  useEffect(() => {
    if (points[activeIndex].length > 0 && connectToFIrstPointKeyDown) {
      lastPointClick()
    }
  }, [connectToFIrstPointKeyDown])

  useEffect(() => {
    if (points[activeIndex].length > 0 && deleteLastPointKeyDown) {
      deletePointClick()
    }
  }, [deleteLastPointKeyDown])
  useEffect(() => {
    setOpacity(opacity + 1)
  }, [opacityIncreaseKeyDown])
  useEffect(() => {
    setOpacity(opacity - 1)
  }, [opacityDecreaseKeyDown])

  useEffect(() => {
    if(personView){
      setShowPerson(!showPerson)
    }
  }, [personView])

  useEffect(() => {
    if(showPerson){
      setOpacity(10)
    }else{
      setOpacity(4)
    }
  }, [showPerson])



  useEffect(() => {
    if((points[activeIndex].length > 0 || showSave))
    if (points[activeIndex - 1].length > 2 || showSave) {
      // console.log(points[activeIndex -1], activeIndex, 'here')
      if (
        points[activeIndex - 1][0] == lastPoint ||
        points[activeIndex - 1][0] ==
          points[activeIndex - 1][points[activeIndex - 1].length - 1] ||
        showSave
      ) {
        if(!mutationLoading)
        saveSegmentedImage()
      }
    }
  }, [saveKeyDown])

  const { data: person_data } = useQuery(GET_IMAGE_URL, {
    variables: {
      id: id,
    },
  });

  useEffect(() => {
    let pred = new Image();
    pred.src = image;
    pred.crossOrigin = "anonymous";
    pred.onload = async () => {
      const imageCan = document.createElement("canvas");
      let ctx = imageCan.getContext("2d");
      imageCan.style.width = 600 + "px";
      imageCan.style.height = 800 + "px";
      imageCan.width = Math.floor(600 * 1);
      imageCan.height = Math.floor(800 * 1);
      ctx.drawImage(
        pred,
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight,
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight
      );
      const imageData = ctx.getImageData(
        0,
        0,
        pred.naturalWidth,
        pred.naturalHeight
      );
      let count = 1;
      for (let i = 0; i < imageData.data.length; i += 1) {
        if (
          imageData.data[i] < 30 &&
          imageData.data[i] < 30 &&
          imageData.data[i] < 30
        ) {
          count += 1;
        }
      }
      setPredictionData(imageData.data);
      if (pred.naturalWidth < 600) {
        let fin = tf.browser.fromPixels(imageData);
        let ten = resize(fin, 600, 800, true);
        setPredictionData(ten);
      }
    };
  }, [image, props.loadImage]);

  const [savePredGood] = useMutation(UPDATE_PRED_GOOD, {
    refetchQueries: [{ query: GET_IMAGE_URL, variables: { id: id } }],
  });
  useEffect(() => {
    let fields = {};
    for (const property in DEFAULT_VALUES) {
      fields[property] = person_data[TABLE][0][property] || DEFAULT_VALUES[property];
    }
    setFormFields(fields);
    setNaReason(
      person_data[TABLE][0].reason_for_na == null
        ? "No"
        : person_data[TABLE][0].reason_for_na
    );
    if(person_data[TABLE][0]['prediction_type']){
      setPredType(person_data[TABLE][0]['prediction_type'])
    }
  }, [person_data]);

  useEffect(() => {
    previewSegmentedImage()
  }, [predType])
  const [
    save_all,
    { loading: mutationLoading },
  ] = useMutation(UPDATE_PERSON, {
    onCompleted() {
      if (mutateType == "saved") {
        window.location.reload();
      }
      else {
        setSavedImg(true);
      }
    },
    onError(data) {
      alert(
        `Your segmentations did not save. Please check your internet connection.\n For technical purpose: ${data} `
      );
    },
    refetchQueries: [
      {
        query: GET_IMAGE_URL,
        variables: {
          id: id,
        },
      },
    ],
  });

  const [points, setPoints] = useState([[]]);
  const [pointsColor, setPointsColor] = useState([
    { colorSave: "rgba(255, 85, 0, 255)", colorView: "rgba(255, 85, 0, 255)", colorPreview: "rgba(255, 85, 0, 255)" },
  ]);

  const [lastPoint, setLastPoint] = useState({});
  const [penultimatePoint, setPenultimatePoint] = useState({});
  const [toggle, setToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [savedImg, setSavedImg] = useState(false);
  const [activeVariable, setActiveVariable] = useState("");
  const [savingPart, setSavingPart] = useState(false);
  const [naReason, setNaReason] = useState("No");
  const [mutateType, setMutateType] = useState("saved");
  const [ignorePart, setIgnorePart] = useState("");
  const [liveP, setLiveP] = useState("");
  const [livePrediction, setLivePrediction] = useState("")
  useEffect(() => {
    canvas = document.getElementById(canvas_name);
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "black";
  }, []);

  useEffect(() => {
    canvas = document.getElementById(canvas_name);
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    ctx.scale(scale, scale);
  }, [SIZEX, SIZEY]);

  useEffect(() => {
    if (person_data && !mutationLoading) {
      canvas = document.getElementById(canvas_name);
      const instaCanvas = canvas;
      let ctx = instaCanvas.getContext("2d");
      canvas.style.width = SIZEX + "px";
      canvas.style.height = SIZEY + "px";
      var scale = 1;
      canvas.width = Math.floor(SIZEX * scale);
      canvas.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);

      let list = [];
      Object.keys(allParts).forEach((key) => {
        if (
          startFromScratchOrUseSaved[allParts[key]["variable"]] &&
          startFromScratchOrUseSaved[allParts[key]["variable"]] != "scratch"
        ) {
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        } else if (
          startFromScratchOrUseSaved[allParts[key]["variable"]] &&
          startFromScratchOrUseSaved[allParts[key]["variable"]] != "scratch" &&
          person_data[TABLE][0][allParts[key]["variable"]] !== null
        ) {
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        } else {
          if (!startFromScratchOrUseSaved[allParts[key]["variable"]]) {
            list.push(person_data[TABLE][0][allParts[key]["variable"]]);
          }
        }
      });

      Object.keys(segmentationsPerClass).forEach((key) => {
        if (segmentationsPerClass[key]) {
          list = list.concat(segmentationsPerClass[key]);
        }
      });
      createImage(ctx, list, SIZEX, SIZEY);
      if (activeIndex > 0) {
        for (let j = 0; j < points.length - 1; j++) {
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.fillRect(
              points[j][i].x - OFFSET_X,
              points[j][i].y - OFFSET_Y,
              3,
              3
            );
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.globalAlpha = 0.7;
          ctx.fillStyle = pointsColor[j].colorView;
          ctx.fill();
          ctx.stroke();
        }
      }
      if (penultimatePoint) {
        ctx.beginPath();
        if (points[activeIndex].length > 0) {
          ctx.moveTo(points[0].x, points[0].y);
          ctx.fillRect(
            points[activeIndex][0].x - OFFSET_X,
            points[activeIndex][0].y - OFFSET_Y,
            3,
            3
          );
        }
        for (let i = 0; i < points[activeIndex].length; i++) {
          ctx.fillRect(
            points[activeIndex][i].x - OFFSET_X,
            points[activeIndex][i].y - OFFSET_Y,
            3,
            3
          );
          ctx.lineTo(
            points[activeIndex][i].x - OFFSET_X,
            points[activeIndex][i].y - OFFSET_Y
          );
        }
        if (toggle) {
          ctx.lineWidth = 2;
          ctx.globalAlpha = 0.7;
          ctx.fillStyle = pointsColor[activeIndex].colorView;
          ctx.fill();
        }
        ctx.stroke();
      }
      // }
    }
  }, [lastPoint, activeVariable, startFromScratchOrUseSaved, person_data, opacity, update]);



  const capturePoint = (evt) => {
    // console.log(evt)
    if (activeIndex == 0) {
      alert("Please select the body part that you want to color");
      return;
    }
    const mousePos = getMousePos(canvasRef, evt, lastPoint);
    if (mousePos){
      if ( (mousePos.x >= 0 || mousePos.x <= 600) && (mousePos.y >= 0 || mousePos.y <= 800)){
        const temp = points;
        temp[activeIndex].push(mousePos);
        setPoints(temp);
        setPenultimatePoint(lastPoint);
        setLastPoint(mousePos);
        setToggle(false);
      }
    }

  };

  const capturePointOutside = (evt) => {
    // alert("outside click")
    const mousePos = getMousePos(containerRef, evt, lastPoint);
    if(mousePos){
      if ( mousePos.x == 0 || mousePos.x == 600 || mousePos.y == 0 || mousePos.y == 800){
        if (activeIndex == 0) {
          alert("Please select the body part that you want to color");
          return;
        }
        const temp = points;
        temp[activeIndex].push(mousePos);
        setPoints(temp);
        setPenultimatePoint(lastPoint);
        setLastPoint(mousePos);
        setToggle(false);
        console.log(mousePos, "mousemove")
      }
    }
    
  }

  const lastPointClick = () => {
    setToggle(true);
    let temp = points;
    let tempPoints = points[activeIndex];
    tempPoints = [...tempPoints, tempPoints[0]];
    temp[activeIndex] = tempPoints;
    temp.push([[]]);
    let tempColors = pointsColor;
    tempColors = [...tempColors, pointsColor[activeIndex]];
    setPoints(temp);
    setPointsColor(tempColors);
    setLastPoint(points[activeIndex][0]);
    setActiveIndex(activeIndex + 1);
    previewSegmentedImage();
  };

  const deletePointClick = () => {
    const temp = points;
    if (points[activeIndex].length === 1) {
      if (activeIndex > 0) {
        const temp = points.slice(0, points.length - 1);
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        setToggle(false);
        setPoints([...temp]);
        setPointsColor([...tempColor]);
        setLastPoint(
          points[activeIndex - 1][points[activeIndex - 1].length - 1]
        );
        setActiveIndex(activeIndex - 1);
        setUpdate(!update);
      } else {
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        setActiveIndex(0);
        setPoints([[]]);
        setPointsColor([...tempColor]);
        setToggle(false);
        setLastPoint({});
      }
    } else {
      const tempPoints = points[activeIndex].slice(
        0,
        points[activeIndex].length - 1
      );
      temp[activeIndex] = tempPoints;
      setToggle(false);
      setPoints([...temp]);
      setLastPoint(points[activeIndex][points[activeIndex].length - 2]);
    }
  };


  const livePreview = async () => {
    // alert(image)
    if(person_data && person_data[TABLE][0]['prediction_type']==props.loadImage){
    let list = [];
    for (let i = 0; i < allParts.length; i++) {
      let seg = person_data[TABLE][0][allParts[i]["variable"]];
      if ((seg != null || seg != undefined)) {
        list.push(seg);
      }
    }

    let predImg = new Image();
    predImg.src = image;
    predImg.crossOrigin = "anonymous";
    predImg.onload = async function () {
      let predCanvas = document.createElement("canvas");
      predCanvas.width = SIZEX;
      predCanvas.height = SIZEY;
      let ctx2 = predCanvas.getContext("2d");
      ctx2.drawImage(
        predImg,
        0,
        0,
        predImg.naturalWidth,
        predImg.naturalHeight,
        0,
        0,
        SIZEX,
        SIZEY
      );
      let data = predCanvas.toDataURL();
      list.unshift(data);


      await mergeImages(list).then((b64) => {
        setLiveP(b64)
        // var canvas = document.getElementById("livePreview");
        // var ctx = canvas.getContext("2d");

        // var image = new Image();
        // image.crossOrigin = "anonymous";
        // image.onload = function () {
        //   ctx.drawImage(
        //     image,
        //     0,
        //     0,
        //     image.naturalWidth,
        //     image.naturalHeight,
        //     0,
        //     0,
        //     SIZEX / 2,
        //     SIZEY / 2
        //   );
        // };
        // image.src = b64;

        var canvasBig = document.getElementById("livePreviewBig");
        var ctxBig = canvasBig.getContext("2d");



        var imageBig = new Image();
        imageBig.onload =  function () {
          ctxBig.drawImage(
            imageBig,
            0,
            0,
            imageBig.naturalWidth,
            imageBig.naturalHeight,
            0,
            0,
            SIZEX,
            SIZEY
          );

          const imageData = ctxBig.getImageData(0, 0, 600, 800);

          make_rest_transparent(imageData.data, 255);
          ctxBig.putImageData(imageData, 0, 0);
        };
        imageBig.src = b64;

      });
    };
  }
  };

  useEffect(() => {
    livePreview();
    previewSegmentedImage()
  }, [person_data, props.loadImage, image]);

  useEffect(() => {
    if (liveP !== "") {
      let newDummyImg = new Image();
      newDummyImg.src = liveP;
      newDummyImg.crossOrigin = "anonymous";
      newDummyImg.onload = () => {
        URL.revokeObjectURL(newDummyImg.src);
        canvas = document.createElement("canvas");
        const instaCanvas = canvas;
        let ctx = instaCanvas.getContext("2d");
        canvas.style.width = SIZEX + "px";
        canvas.style.height = SIZEY + "px";
        var scale = 1;
        canvas.width = Math.floor(SIZEX * scale);
        canvas.height = Math.floor(SIZEY * scale);
        ctx.scale(scale, scale);
        ctx.drawImage(
          newDummyImg,
          0,
          0,
          newDummyImg.naturalWidth,
          newDummyImg.naturalHeight,
          0,
          0,
          SIZEX,
          SIZEY
        );

        const imageData = ctx.getImageData(0, 0, 600, 800);

        allParts.map((part => {
          if (part == activeVariable) {
            return part
          }
        }))

        make_rest_transparent(imageData.data);
        let colors = showPart.substring(5);
        if (colors == "185, 185, 185, 255)") {
          colors = "0,0,0,255)"
        }
        colors = colors.split(",");
        if (showPart != "") {
          show_single_part(imageData.data, colors)
        }
        ctx.putImageData(imageData, 0, 0);
        const picURL= canvas.toDataURL();
        setLiveReview(picURL)
      }
    }
  }, [liveP, showPart, props.loadImage]);

  useEffect(() => {
    makePredictionData(image, setLivePrediction, SIZEX, SIZEY)
  }, [image, props.loadImage]);

  const savePart = (canvas, colorToSave) => {
    let flag = false;
    let ctx = canvas.getContext("2d");
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, SIZEX, SIZEY);
    ctx.restore();
    if (activeIndex >= 0) {
      for (let j = 0; j < points.length; j++) {
        if (pointsColor[j].colorPreview == colorToSave) {
          if (flag === false) {
            flag = true;
          }
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 2;
          ctx.fillStyle = pointsColor[j].colorPreview;
          ctx.strokeStyle = pointsColor[j].colorPreview;
          ctx.fill();
          ctx.stroke();
        }
      }
    }
    const picURL = canvas.toDataURL();
    setSavingPart(!savingPart);
    if (flag == false) {
      return null;
    } else {
      return picURL;
    }
  };

  const saveSegmentedImage = async () => {
    canvas = document.getElementById(canvas_name);
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    ctx.scale(scale, scale);

    let allVariables = {};
    allVariables["id"] = id;
    for (let i = 0; i < allParts.length; i += 1) {
      const pic = await savePart(
        canvas,
        allParts[i]["color"],
      );
      if (pic != null) {
        if (
          person_data[TABLE][0][allParts[i]["variable"]] &&
          startFromScratchOrUseSaved[allParts[i]["variable"]] == "save"
        ) {
          let list = [];
          if (
            segmentationsPerClass[allParts[i]["variable"]] != null ||
            segmentationsPerClass[allParts[i]["variable"]] != undefined
          ) {
            list = list.concat(segmentationsPerClass[allParts[i]["variable"]]);
          }
          list.push(person_data[TABLE][0][allParts[i]["variable"]]);
          list.push(pic);
          await mergeImages(list).then((b64) => {
            allVariables[allParts[i]["variable"]] = b64;
          });
        } else {
          // alert("comes here")
          let list = [];
          if (
            segmentationsPerClass[allParts[i]["variable"]] != null ||
            segmentationsPerClass[allParts[i]["variable"]] != undefined
          ) {
            list = list.concat(segmentationsPerClass[allParts[i]["variable"]]);
          }
          list.push(pic);
          await mergeImages(list).then((b64) => {
            allVariables[allParts[i]["variable"]] = b64;
          });
          if(startFromScratchOrUseSaved[allParts[i]["variable"]]!="scratch"){
            allVariables[allParts[i]["variable"]] = person_data[TABLE][0][allParts[i]["variable"]]
          }
        }
      } else {
        let list = [];
        if (
          segmentationsPerClass[allParts[i]["variable"]] != null ||
          segmentationsPerClass[allParts[i]["variable"]] != undefined
        ) {
          list = list.concat(segmentationsPerClass[allParts[i]["variable"]]);
        }
        if (person_data[TABLE][0][allParts[i]["variable"]] != null) {
          list.push(person_data[TABLE][0][allParts[i]["variable"]]);
        }
        if (list.length > 0) {
          await mergeImages(list).then((b64) => {
            allVariables[allParts[i]["variable"]] = b64;
          });
        } else {
          allVariables[allParts[i]["variable"]] = null;
        }
      }
    }
    allVariables["name"] = props.name;
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    allVariables["last_updated"] = moment()
    allVariables["worst_case_labelled"] = person_data[TABLE][0]["worst_case_labelled"] == "Not Labelled" ? "Labelled" : null;
    setMutateType("saved");
    allVariables["prediction_type"] = props.loadImage;
    allVariables = { ...allVariables, ...formFields }
    await save_all({
      variables: allVariables,
    });
  };

  const previewSegmentedImage = async (previewSeg = true) => {
    if(predType){
    console.log(person_data, props.loadImage)
    canvas = document.getElementById("preview_canvas");
    const instaCanvas = canvas;
    let ctx = instaCanvas.getContext("2d");
    canvas.style.width = SIZEX + "px";
    canvas.style.height = SIZEY + "px";
    var scale = 1;
    canvas.width = Math.floor(SIZEX * scale);
    canvas.height = Math.floor(SIZEY * scale);
    let list = [];
    const personList = []
    Object.keys(allParts).forEach((key) => {
      if (
        startFromScratchOrUseSaved[allParts[key]["variable"]] &&
        startFromScratchOrUseSaved[allParts[key]["variable"]] != "scratch"
      ) {
        if (person_data[TABLE][0][allParts[key]["variable"]] && allParts[key]["variable"]=="background_seg") {
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        }else{
          personList.push(person_data[TABLE][0][allParts[key]["variable"]])
        }
      } else if (
        startFromScratchOrUseSaved[allParts[key]["variable"]] &&
        startFromScratchOrUseSaved[allParts[key]["variable"]] != "scratch" &&
        person_data[TABLE][0][allParts[key]["variable"]] !== null
      ) {
        if(allParts[key]["variable"]=="background_seg"){
          list.push(person_data[TABLE][0][allParts[key]["variable"]]);
        }else{
          personList.push(person_data[TABLE][0][allParts[key]["variable"]])
        }
      } else {
        if (
          !startFromScratchOrUseSaved[allParts[key]["variable"]] &&
          person_data[TABLE][0][allParts[key]["variable"]] != null
        ) {
          if(allParts[key]["variable"]=="background_seg"){
            list.push(person_data[TABLE][0][allParts[key]["variable"]]);
          }else{
            personList.push(person_data[TABLE][0][allParts[key]["variable"]])
          }
        }
      }
    });

    Object.keys(segmentationsPerClass).forEach((key) => {
      if (segmentationsPerClass[key]) {
        list = list.concat(segmentationsPerClass[key]);
      }
    });
    console.log(personList.length)

    ctx.scale(scale, scale);
    
    if (props.loadImage != "segment") {
      let newDummyImg = new Image();
      newDummyImg.src = image;
      newDummyImg.crossOrigin = "anonymous";
      newDummyImg.onload = () => {
        URL.revokeObjectURL(newDummyImg.src);
        canvas = document.getElementById("preview_canvas");
        const instaCanvas = canvas;
        let ctx = instaCanvas.getContext("2d");
        ctx.drawImage(
          newDummyImg,
          0,
          0,
          newDummyImg.naturalWidth,
          newDummyImg.naturalHeight,
          0,
          0,
          SIZEX,
          SIZEY
        );
        const  predUrl = canvas.toDataURL();
        if(props.loadImage!="scratch"){
          list.unshift(predUrl)
        }
        ctx.clearRect(0,0, SIZEX, SIZEY);
        let pointsArrangedByColor = [];
        let colorsArranged = [];
        for (let j = 0; j < points.length - 1; j++){
          if(pointsColor[j].colorPreview=="rgba(255, 85, 0, 255)"){
            pointsArrangedByColor.push(points[j])
            colorsArranged.push(pointsColor[j])
          }else{
            pointsArrangedByColor.unshift(points[j])
            colorsArranged.unshift(pointsColor[j])
          }
        }
        // console.log(colorsArranged, pointsArrangedByColor)
        if (activeIndex >= 0) {
          for (let j = 0; j < pointsArrangedByColor.length; j++) {
            console.log(colorsArranged[j], j)
            ctx.beginPath();
            if (pointsArrangedByColor[j].length > 0) {
              ctx.moveTo(pointsArrangedByColor[j][0].x, pointsArrangedByColor[j][0].y);
            }
            for (let i = 1; i < pointsArrangedByColor[j].length; i++) {
              ctx.lineTo(pointsArrangedByColor[j][i].x - OFFSET_X, pointsArrangedByColor[j][i].y - OFFSET_Y);
            }
            ctx.lineWidth = 2;
            ctx.fillStyle = colorsArranged[j].colorPreview;
            ctx.strokeStyle = colorsArranged[j].colorPreview;
            ctx.fill();
            ctx.stroke();
          }
        }
        const imageData = ctx.getImageData(0, 0, 600, 800);
        // make_rest_transparent(imageData.data);
        ctx.putImageData(imageData, 0, 0);
        
        const picURL = canvas.toDataURL();
        list.push(picURL);
        let finalImage = new Image();
        finalImage.crossOrigin = "anonymous";
        list = list.concat(personList)
        let finalList = []
        for (let j = 0; j < list.length; j++){
          if(list[j] && list[j]!=null && list[j]!=undefined){
            finalList.push(list[j])
          }
        }
        console.log(finalList)
        // console.log(person_data[TABLE][0]['silhouette_seg'], "In preview segmented image")
        mergeImages(finalList).then((b64) => {
          finalImage.src = b64;
          finalImage.onload = () => {
            ctx.drawImage(finalImage, 0, 0, SIZEX, SIZEY);
            let data = ctx.getImageData(0, 0, 600, 800);
            make_rest_transparent(data.data);
            getPersonFromImage(data.data, props.url, setPreview, setDataUrl);
          }
          
        });

        LEFT_POSITION = "2400px";
        // setPreview(true);
      };
    }
  }
  };

  const fillRest = async () => {
    if (predictionData) {
      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      createAllSegments(activeIndex, points, ctx, pointsColor, OFFSET_X, OFFSET_Y)
      let data = ctx.getImageData(0, 0, 600, 800);
      let count = 1;
      for (let i = 0; i < data.data.length; i += 4) {
        if (
          data.data[i] < 30 &&
          data.data[i + 1] < 30 &&
          data.data[i + 2] < 20
        ) {
          count += 1;
          data.data[i] = 0;
          data.data[i + 1] = 0;
          data.data[i + 2] = 0;
          data.data[i + 3] = 0;
        } else {
          if (
            predictionData[i] == 0 &&
            predictionData[i + 1] == 0 &&
            predictionData[i + 2] == 0
          ) {
          } else {
            data.data[i] = 0;
            data.data[i + 1] = 0;
            data.data[i + 2] = 0;
            data.data[i + 3] = 0;
          }
        }
      }
      ctx.putImageData(data, 0, 0);
      const pic = restCan.toDataURL();
      const initArr = segmentationsPerClass;
      if (!initArr[activeVariable]) initArr[activeVariable] = [pic];
      else initArr[activeVariable].push(pic);

      setSegmentationsPerClass(initArr);

      const temp = points;
      let firstPop = temp.pop();
      let second = temp.pop();
      temp.push([[]]);
      const tempColor = pointsColor;
      let color = tempColor.pop();
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);
      setActiveIndex(activeIndex - 1);
      previewSegmentedImage();
    }
  };

  const remove_from_seg = (old_seg) => {

    let newDummyImg = new Image();
    newDummyImg.src = old_seg;
    newDummyImg.crossOrigin = "anonymous";
    newDummyImg.onload = () => {
      URL.revokeObjectURL(newDummyImg.src);
      let old_seg_canvas = document.createElement("canvas");
      let old_seg_canvas_context = old_seg_canvas.getContext("2d");
      old_seg_canvas.style.width = SIZEX + "px";
      old_seg_canvas.style.height = SIZEY + "px";
      old_seg_canvas.width = Math.floor(SIZEX * 1);
      old_seg_canvas.height = Math.floor(SIZEY * 1);
      old_seg_canvas_context.scale(1, 1);
      old_seg_canvas_context.drawImage(
        newDummyImg,
        0,
        0,
        newDummyImg.naturalWidth,
        newDummyImg.naturalHeight,
        0,
        0,
        SIZEX,
        SIZEY
      );

      const old_seg_data = old_seg_canvas_context.getImageData(0, 0, 600, 800);

      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      createAllSegments(activeIndex, points, ctx, pointsColor, OFFSET_X, OFFSET_Y)
      let new_seg_data = ctx.getImageData(0, 0, 600, 800);
      let mySet = new Set()
      for (let i = 0; i < new_seg_data.data.length; i += 4) {
        mySet.add(old_seg_data.data[i])
        if (
          new_seg_data.data[i] > old_seg_data.data[i] - 30 &&
          new_seg_data.data[i] < old_seg_data.data[i] + 30 &&
          new_seg_data.data[i + 1] > old_seg_data.data[i + 1] - 30 &&
          new_seg_data.data[i + 1] < old_seg_data.data[i + 1] + 30 &&
          new_seg_data.data[i + 2] > old_seg_data.data[i + 2] - 30 &&
          new_seg_data.data[i + 2] < old_seg_data.data[i + 2] + 30
        ) {
          old_seg_data.data[i] = 0;
          old_seg_data.data[i + 1] = 0;
          old_seg_data.data[i + 2] = 0;
          old_seg_data.data[i + 3] = 0;
        }
      }

      old_seg_canvas_context.putImageData(old_seg_data, 0, 0);
      person_data[TABLE][0][activeVariable] = old_seg_canvas.toDataURL();
      const initArr = segmentationsPerClass;
      setSegmentationsPerClass(initArr);
      const temp = points;
      let firstPop = temp.pop();
      let second = temp.pop();
      temp.push([[]]);
      const tempColor = pointsColor;
      let color = tempColor.pop();
      setActiveIndex(activeIndex - 1);
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);

      previewSegmentedImage();
    }
  }
  const newFillRest = async (ignorePart) => {
    let colors = ignorePart.substring(5);
    if (colors == "185, 185, 185, 255)") {
      colors = "0,0,0,255)"
    }
    colors = colors.split(",");
    if (predictionData) {
      let restCan = document.createElement("canvas");
      let ctx = restCan.getContext("2d");
      restCan.style.width = SIZEX + "px";
      restCan.style.height = SIZEY + "px";
      restCan.width = Math.floor(SIZEX * 1);
      restCan.height = Math.floor(SIZEY * 1);
      createAllSegments(activeIndex, points, ctx, pointsColor, OFFSET_X, OFFSET_Y)
      let data = ctx.getImageData(0, 0, 600, 800);
      let count = 1;
      for (let i = 0; i < data.data.length; i += 4) {
        if (
          data.data[i] < 30 &&
          data.data[i + 1] < 30 &&
          data.data[i + 2] < 20
        ) {
          count += 1;
          data.data[i] = 0;
          data.data[i + 1] = 0;
          data.data[i + 2] = 0;
          data.data[i + 3] = 0;
        } else {
          if (
            !(
              predictionData[i] == parseInt(colors[0].trim()) &&
              predictionData[i + 1] == parseInt(colors[1].trim()) &&
              predictionData[i + 2] == parseInt(colors[2].trim())
            )
          ) {
          } else {
            data.data[i] = 0;
            data.data[i + 1] = 0;
            data.data[i + 2] = 0;
            data.data[i + 3] = 0;
          }
        }
      }
      ctx.putImageData(data, 0, 0);
      const pic = restCan.toDataURL();
      const initArr = segmentationsPerClass;
      if (!initArr[activeVariable]) initArr[activeVariable] = [pic];
      else initArr[activeVariable].push(pic);
      setSegmentationsPerClass(initArr);

      const temp = points;
      let firstPop = temp.pop();
      let second = temp.pop();
      temp.push([[]]);
      const tempColor = pointsColor;
      let color = tempColor.pop();
      setPenultimatePoint({});
      setLastPoint({});
      setPointsColor(tempColor);
      setPoints(temp);
      setToggle(false);
      setShowSave(true);
      setActiveIndex(activeIndex - 1);
      previewSegmentedImage();
    }
  };



  useEffect(() => {
    if (ignorePart != "") {
      newFillRest(ignorePart);
      setIgnorePart("");
    }
  }, [ignorePart]);

  const deleteOldSeg = () => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        if (person_data[TABLE][0][activeVariable]) {
          remove_from_seg(person_data[TABLE][0][activeVariable])
        }
      }
  }


  const deleteSeg = async () => {
    let allVariables = {};
    allVariables["id"] = id;
    allParts.map((part) => {
      allVariables[part["variable"]] = person_data[TABLE][0][part["variable"]];
    });
    allVariables["name"] = props.name;
    allVariables[activeVariable] = null;
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    setMutateType("deleted");
    allVariables["prediction_type"] = person_data[TABLE][0]["prediction_type"];
    allVariables["last_updated"] = moment()
    allVariables["worst_case_labelled"] = person_data[TABLE][0]["worst_case_labelled"] == "Not Labelled" ? "Labelled" : null;
    allVariables = { ...allVariables, ...formFields }
    await save_all({
      variables: allVariables,
    });
  };

  const [showUseLabel, setShowUseLabel] = useState(false);

  const addAnotherPath = (
    save,
    view,
    preview,
    part = null,
    variable = null
  ) => {
    setShowSave(false);
    setShowUseLabel(true);
    setActiveVariable(variable);
    if (person_data && person_data[TABLE][0][variable]) {
      setStartFromScratchOrUseSaved({ ...startFromScratchOrUseSaved, [variable]: "save" });
    } else {
      setStartFromScratchOrUseSaved({ ...startFromScratchOrUseSaved, [variable]: "scratch" });
    }
    const temp = points;
    temp.push([]);
    const tempColor = pointsColor;
    tempColor.push({
      colorSave: save,
      colorView: view,
      colorPreview: preview,
    });
    setActiveIndex(activeIndex + 1);
    setPenultimatePoint({});
    setLastPoint({});
    setPointsColor(tempColor);
    setPoints(temp);
    setToggle(false);
  };

  const markAsNA = async () => {
    if (!naReason) {
      alert("Please mention the reason for marking the image as NA");
      return;
    }
    if (person_data) {
      let flag = false;
      allParts.map((part) => {
        if (person_data[TABLE][0][part.variable] != null) {
          flag = true;
        }
      });
      if (naReason === "No") {
        await saveAppropriate({
          variables: {
            id: id,
            is_na: "No",
            reason_for_na: naReason,
            isLabelled: flag ? "Labelled" : "Not Labelled",
            last_updated: moment(),
            ...formFields
          },
        });
      } else {
        await saveAppropriate({
          variables: {
            id: id,
            is_na: "Yes",
            reason_for_na: naReason,
            isLabelled: "Labelled",
            last_updated: moment(),
            ...formFields
          },
        });
      }
    }
  };

  const markAsGood = async () => {
    await savePredGood({
      variables: {
        id: id,
        is_perfect:
          person_data && person_data[TABLE][0]["is_perfect"] == "Yes"
            ? "No"
            : "Yes",
      },
    });
    if (person_data[TABLE][0]["is_perfect"] == "No") {
      nextImage();
    }
  };

  const showRadioButton = () => {
    if (person_data && person_data[TABLE][0][activeVariable]) {
      return (
        <div className="display--flex radio--color"> 
          <div>
            <RadioGroup
              value={startFromScratchOrUseSaved[activeVariable]}
              onChange={(e) => {
                if (
                  e.target.value == "save" &&
                  !person_data[TABLE][0][activeVariable]
                ) {
                  alert("No previous label is save for this class");
                  return;
                }
                setStartFromScratchOrUseSaved({
                  ...startFromScratchOrUseSaved,
                  [activeVariable]: e.target.value,
                });
              }}
            >
              <Radio value={"save"}>Use Saved Label</Radio>
              <Radio value={"scratch"}>Start from Scratch</Radio>
            </RadioGroup>
          </div>
          <div>
            <Button onClick={() => deleteSeg()}>Delete segmentation</Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };



  // console.log(startFromScratchOrUseSaved)
  return (
    <div>
      <div
        className="display--flex main_container"
      >
        <WorkingCanvas
          image={image}
          livePrediction={livePrediction}
          capturePoint={capturePoint}
          canvasRef={canvasRef}
          canvas_name={canvas_name}
          opacity={opacity}
          toggleImage={props.toggleImage}
          url={props.url}
          person_id={props.person_id}
          sizeX={SIZEX}
          sizeY={SIZEY}
          capturePointOutside={capturePointOutside}
          containerRef={containerRef}
        />
        <div className="action-area--container">
          {points[activeIndex].length > 0 && (
            <div>
              <Button
                className="margin--10px button--height"
                onClick={lastPointClick}
              >
                Connect to first point
              </Button>
            </div>
          )}
          {points[activeIndex].length > 0 && (
            <div>
              <Button
                className="margin--10px button--height"
                onClick={deletePointClick}
              >
                Delete last point
              </Button>
            </div>
          )}
          {activeVariable && person_data[TABLE][0][activeVariable] && (
            <div>
              <Button
                className="margin--10px button--height"
                onClick={() => deleteOldSeg()}
              >
                Delete old segmentation
              </Button>
            </div>
          )}
          <div>
            {PRED_GOOD_AVAILABLE && (
              <Button variantColor="blue" onClick={markAsGood}>
                {person_data && person_data[TABLE][0]["is_perfect"] == "Yes"
                  ? "Prediction Bad"
                  : "Prediction Good"}
              </Button>
            )}
          </div>
          {(points[activeIndex].length > 0 || showSave) && checkConnection(points, activeIndex, lastPoint, showSave, saveSegmentedImage)}
          {(points[activeIndex].length > 0 || showSave) &&
            checkConnectionAndFillWithRest(points, activeIndex, lastPoint, showSave, fillRest)}
          <div>
            {(points[activeIndex].length > 0 || showSave) &&
              checkConnectionAndFillWithSome(points, activeIndex,activeVariable, lastPoint, showSave, allParts, ignorePart, setIgnorePart)}
          </div>
          {savedImg && <div>The image is {mutateType} !</div>}
          {person_data && person_data[TABLE][0]["is_na"] == "Yes" && (
            <div>The image is marked as NA</div>
          )}
          <div className="display--flex">
            <div className="main_container--width">
              <div className="main_container--width">
                <div className="display--flex">
                  <Button onClick={() => setOpacity(opacity + 1)}>+</Button>
                  <div className="margin_left--10px margin_right--10px">
                    <b>{opacity}</b>
                  </div>
                  <Button onClick={() => setOpacity(opacity - 1)}>-</Button>
                  <Button className="next--margin" onClick={() => nextImage()}>Next </Button>
                </div>
                <div>{showUseLabel && showRadioButton()}</div>
              </div>
              <div className="display--flex ">
                <div
                  className="display--flex flex-wrap--wrap justify-content--center"
                >
                  {allParts.map((part) => (
                    <div style={{ ...part["main_class"] }}>
                      <Button
                        onClick={() =>
                          addAnotherPath(
                            part["color"],
                            part["color"],
                            part["color"],
                            person_data[TABLE][0][part["variable"]],
                            part["variable"]
                          )
                        }
                        style={{
                          margin: "3px 0px 0px 0px",
                          backgroundColor: part["color"],
                          border:
                            activeVariable == part["variable"]
                              ? "5px solid black"
                              : "",
                          ...part["classes"],
                        }}
                      >
                        <div>
                          {part["name"]}
                          {part["extra_info"] ? (
                            <div>{part["extra_info"]}</div>
                          ) : null}
                        </div>
                      </Button>
                      {part["desc"] && (
                        <div>
                          <b>{part["desc"]}</b>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
          <div>
            <div className="width--380p">
              <div className="margin--10px">
                Is the image <b> NA </b>? If yes, what is the <b>reason</b> for{" "}
                <b>marking image as NA</b> ?
              </div>
            </div>
            {
              <Select
                value={naReason}
                onChange={(e) => setNaReason(e.target.value)}
                name={naReason}
              >
                <option value="No">No</option>
                <option value="black_white">Black And White</option>
                <option value="back_side_pose">Back/Side Pose (90&deg; and more)</option>
                <option value="slightly_side_pose">SLightly Side Pose ( 45&deg; to less than 90&deg;)</option>
                <option value="weird_pose">Weird Pose (Sitting Down, Weirdly Bent) </option>
                <option value="half_cut">Half cut image of person</option>
                <option value="multiple_people">Multiple People</option>
                <option value="no_person">No person in Image</option>
                <option value="majorly_occluded_image">Body Majorly Occluded by any object, accessory</option>
                <option value="cloth_as_accessory">Wearing Cloth as An accessory</option>
                <option value="watermark_present">Watermark over the person</option>
              </Select>
            }
            {
              <Button
                className="margin-top--20p"
                onClick={() => markAsNA()}
              >
                Save
              </Button>
            }
            {props.name == "admin" && person_data &&
              <a href={`http://34.68.244.109:3006/name=admin/imageid=${person_data[TABLE][0]['map_grapy']['id']}`} target={"_blank"}><h4>View Grapy</h4></a>
            }
            <b>ASSIGN</b>
            <div
            >
              {allPartsForAssign.map((part) => (
                <div
                  className="display--flex width--380p"
                >
                  <div
                    style={{
                      height: "40px",
                      lineHeight: "40px",
                      width: "60%",
                      backgroundColor: part["color"],
                    }}
                    className="assign-button"
                  >
                    {part["name"]}
                  </div>
                  <Select value={formFields[make_assign_variable(part['variable'])]} onChange={onInputChange} name={make_assign_variable(part['variable'])} width="200px">
                    <option value="">None</option>
                    {allParts.map((op) => {
                      if (op["variable"] != part["variable"]) {
                        return (
                          <option value={op["variable"]}>{op["name"]}</option>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </div>
              ))}
            </div>
          </div>
          <div>
          </div>
        </div>

        <div className="display--flex">
          <canvas
            // className="frame-temp"
            id={"preview_canvas"}
            className="display--none"
          />
          {person_data && dataUrl && (
            <img src={dataUrl} style={{ width: SIZEX, height: SIZEY }} />
          )}
          <div>
            {/* <div style={{ position: "absolute", height: SIZEX / 2 }}> */}
              {/* <canvas
                className=""
                id="livePreview"
                width={SIZEX / 2}
                height={SIZEY / 2}
                className="live-preview"
              /> */}
            {/* </div> */}

            <div>
              <div>
              </div>
              {/* <div className="pred_after_work--text">
                <b>Prediction after work</b>
              </div> */}
              <div>
                <img
                  className="person-image--img"
                  src={props.url}
                  width={SIZEX}
                  height={SIZEY }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Review
        toggleImage={props.toggleImage}
        url={props.url}
        person_id={props.person_id}
        liveReview={liveReview}
        preview={preview}
        person_data={person_data}
        sizeX={SIZEX}
        size={SIZEY}
        showPart={showPart}
        setShowPart={setShowPart}
        allParts={allParts}
        reviewImage = {dataUrl}
      />
    </div>
  );
};

export default MapPoints;
